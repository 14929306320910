const sliderList = [
  {
    id: 1,
    image: "/assets/img/cars/audi-a6.png",
    title: "GET 25% OFF YOUR RENTAL",
    description: "We have advantageous offers for your first rental",
  },
  {
    id: 2,
    image: "/assets/img/cars/volvo-s90.png",
    title: "HYGIENIC VEHICLES WAIT YOU",
    description: "You can safely drive our hygienic vehicles",
  },
];

export default sliderList;
